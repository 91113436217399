import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SmModalModule } from './shared/components/modal/modal.module';
import { from, Observable, of } from 'rxjs';
import { LanguageService } from './core/service/language.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	public apiUrl = environment.aipUrl;
	public fileUrl = environment.fileUrl;
	public thumbUrl = environment.thumbUrl;
	public settings: any = {
	};
	defaultOptions: any = {}
	public user: any = false
	public languages: any = [];

	constructor(
		public HttpClient: HttpClient,
		public router: Router,
		public translateService: TranslateService,
		private snackBar: MatSnackBar,
		public dialog: MatDialog,
		public languageService: LanguageService,
		public mm: SmModalModule) {
		this.loadSettings();
		this.getCurrentUser();
		//setTimeout(() => this.uploadTranslates(), 1000);
	}

	saveSettings = function () {
		localStorage.setItem('data_applyApp', JSON.stringify(this.settings));
	}

	async getCurrentUser() {
		if (localStorage.isLoggedin == "true" && this.settings.loginData) {
			let resp: any = await this.request("user/currentUser", {});
			if (resp.ok) {
				this.user = resp.user;
				localStorage.setItem('isLoggedin', 'true');
				return true;
			}
			else {
				localStorage.removeItem('isLoggedin');
				this.router.navigate(['/login']);
				return false;
			}
		}
	}

	async getLanguages() {
		if (!this.languages.length) {
			this.languages = await this.request("language/get", {});
			this.translateService.addLangs(this.languages.map(l => l.code));
		}
		return Promise.resolve();
	}

	isAdminLoggedIn() {
		if (this.user && this.user.isAdmin == '1') {
			return true;
		}
		return false;
	}

	trns(text) {
		return this.translateService.instant(text);
	}

	print(text: string) {
		var printHtml = window.open('', 'PRINT', `height=900,width=1240`);

		printHtml.document.write(`<html><body>
		<style>
			.hideInPreview, .entry-length0, .hideInPreview.d-none { display: none !important; }
			ul[dnd-list] {
				min-height: 42px;
				padding-left: 0px;
			}
			ul[dnd-list] .dndDraggingSource {
				display: none;
			}
			ul[dnd-list] li {
				display: block;
			}
		</style>
		`);
		printHtml.document.write(
			`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">`
			+ text
		);
		printHtml.document.write('</body></html>');

		printHtml.document.close();
		printHtml.focus();

		setTimeout(() => {
			printHtml.print();
			printHtml.close();
		}, 600);
	}

	uploadTranslates() {
		this.HttpClient.get('assets/i18n/en_flt.json').subscribe(async d => {
			for (var key in d) {
				await this.request("translate/saveOneItem", { key });
				await this.request("translate/saveOneItem", { key, code: 'en', value: d[key] });
				await this.request("translate/saveOneItem", { key, code: 'de', value: d[key] });
				await this.request("translate/saveOneItem", { key, code: 'fr', value: d[key] });
				await this.request("translate/saveOneItem", { key, code: 'it', value: d[key] });
			}
		});
	}

	translationsLoaded(): Observable<boolean> {
		if (this.translateService.store.translations[this.translateService.currentLang]) {
			return of(true);
		} else {
			return this.translateService.onLangChange
				.pipe(
					map(res => !!res)
				);
		}
	}

	displayCheckbox(d, r, p) {
		if (p) { return d == "1" ? this.trns('app.yes') : this.trns('app.no') };
		return `<i class="fa fa-${d == "1" ? 'check-' : ''}square"></i>`;
	}

	waitUserReady(func) {
		if (this.user) {
			return func();
		}
		setTimeout(() => {
			this.waitUserReady(func);
		}, 100);
	}

	waitUserpromise() {
		return new Promise((resolve, reject) => {
			this.waitUserReady(resolve);
		});
	}

	loadSettings() {
		const defalutSettings = this.settings;
		const res = localStorage.getItem('data_applyApp');

		if (res === undefined || res === null || res === 'null') {
			this.saveSettings();
		}
		else {
			this.settings = JSON.parse(res);
		}
		//migrate defaults in
		for (let i in defalutSettings) {
			if (typeof this.settings[i] == "undefined") {
				this.settings[i] = defalutSettings[i];
				this.saveSettings();
			}
		}
	}

	request(route, parmsArray: any = {}) {
		var self = this;
		let parms = { ...parmsArray };
		let headers = new HttpHeaders();
		if (this.settings.loginData) {
			headers = headers.set('Authorization', 'Bearer ' + this.settings.loginData);
		}
		//parms.currentLang = this.translateService.currentLang || 'en';
		return new Promise(function (resolve, reject) {///+"&t="+Math.random()
			self.HttpClient.post(self.apiUrl + route, parms, { responseType: 'json', headers: headers }).subscribe(async (d: any) => {
				if (d.error && d.error == "needLogin") {
					return this.router.navigate(['/login']);
				}
				resolve(d);
			});
		});
	}

	getBgImage() {
		var self = this;
		return new Promise(function (resolve, reject) {
			from(self.HttpClient.post(self.apiUrl + 'index_images/get', {}, { responseType: 'json' }))
				.pipe(map((item: any) => `url('${self.fileUrl}${item.image}')`)).subscribe((img: string) => {
					resolve(img);
				});
		});
	}

	public upload(data) {
		//console.log('data',data);

		//add default data
		let headers = new HttpHeaders();
		if (this.settings.loginData) {
			headers = headers.set('Authorization', 'Bearer ' + this.settings.loginData);
		}
		//data.append('currentLang', this.translateService.currentLang || 'en');

		return this.HttpClient.post<any>(this.apiUrl + "file/upload", data, {
			reportProgress: true,
			headers: headers,
			observe: 'events'
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					//console.log('progress',progress);
					return { status: 'progress', message: progress };
				case HttpEventType.Response:
					return event.body;
				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	async logout() {
		await this.request("user/logout", {});
		this.settings = {};
		this.user = false;
		this.saveSettings();
		localStorage.removeItem('isLoggedin');
	}

	handleMessageDefault(resp, func) {
		console.log('handleMessageDefault', resp);
	}

	display(title, text) {
		// const modalRef = this.modalService.open(this.mm.getModal(), {centered: true, windowClass: 'documentDisplay'});
		// modalRef.componentInstance.title = title;
		// modalRef.componentInstance.text = text;
		// setTimeout(()=>{
		// 	let ae: any = document.activeElement;
		// 	ae.blur();
		// }, 100)
	}

	alert(title, text) {
		// const modalRef = this.modalService.open(this.mm.getModal(), {centered: true});
		// modalRef.componentInstance.title = title;
		// modalRef.componentInstance.text = text;
		// setTimeout(()=>{
		// 	let ae: any = document.activeElement;
		// 	ae.blur();
		// }, 100)
	}

	confirm(title, text, doneFunc) {
		const dialogRef = this.dialog.open(this.mm.getModal(), {
			data: { title, text, isConfirm: true },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result == "ok") {
				doneFunc();
			}
		});
	}

	snackSuccess(text: string, duration: number = 3000) {
		this.snackBar.open(text, '', {
			duration,
			verticalPosition: 'top',
			horizontalPosition: 'center',
			panelClass: 'snackbar-success'
		});
	}

	snackError(text: string, duration: number = 3000) {
		this.snackBar.open(text, '', {
			duration,
			verticalPosition: 'top',
			horizontalPosition: 'center',
			panelClass: 'snackbar-danger'
		});
	}

	getDates(data: any, dateFields) {
		for (let field of dateFields) {
			if (!data[field]) {
				data[field] = { year: new Date().getFullYear() - 5, month: 1, day: 1 };
				continue;
			}
			let date = new Date(data[field]);
			data[field] = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
		}
	}

	dateTime() {
		return moment().format("Y-MM-DD hh:m:s");
	}

	date() {
		return new Date().toJSON().slice(0, 10);
	}

	public cachedHeight: any = {};
	calcMaxHeight(item) {
		if (this.cachedHeight[item]) {
			return this.cachedHeight[item];
		}
		let node: any = document.getElementById(item + "_rs");
		if (!node) {
			return 'initial';
		}
		let height: any = ((<any>window).innerHeight - node.getBoundingClientRect().top - 5) + "px";
		this.cachedHeight[item] = height;
		return height;
	}

	googleLogin() {

		if (!(window as any).googleInitialized) {
			(window as any).google.accounts.id.initialize({
				client_id: "89619869960-76li4s3epe5tqdb7545ptruddm6ssqkq.apps.googleusercontent.com",
				callback: async (response) => {
					//console.log('response',response);
					let resp: any = await this.request("user/googleLogin", response);

					if (resp.ok) {
						return await this.afterLogin(resp);
					}
				}
			});
			//(window as any).google.accounts.id.prompt();
		}

		setTimeout(() => {
			(window as any).google.accounts.id.renderButton(
				document.getElementById("googleButtonDiv"),
				{ theme: "outline", size: "large", width: "200" }
			);
		}, 600);

		(window as any).googleInitialized = true;
	}

	async afterLogin(resp: any) {
		this.settings.loginData = resp.token;
		this.user = resp.user;
		localStorage.setItem('isLoggedin', 'true');
		this.saveSettings();
		await this.getCurrentUser();
		this.router.navigate(['/dashboard']);
		setTimeout(() => {
			(window as any).location.reload();
		}, 900);
	}

}
