<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul id="sidebarnav" class="list" [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}" [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class="image">
              <img *ngIf="api.user.googlepicture" [src]="api.user.googlepicture" class="img-circle user-img-circle" [alt]="api?.user?.l" />
              <i *ngIf="!api.user.googlepicture" class="fas fa-user"></i>
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name"> {{api?.user?.l}} </div>
            <div class="profile-usertitle-job "> {{api?.user?.tenant?.name}} </div>
            <div class="profile-usertitle-job "> {{'Tokens:' | translate}} {{api?.user?.tenant?.tokensLeft}} / {{api?.user?.tenant?.tokens}} </div>
          </div>
        </li>
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0" *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <i [class]="sidebarItem.icon"></i>
            <!--i-feather [name]="sidebarItem.icon" class="sidebarIcon"></i-feather-->
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''" *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]" (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                <i [class]="sidebarSubItem.icon"></i>
                {{sidebarSubItem.title | translate}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu" [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]" (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
