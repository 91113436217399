<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="javascript:void(0)" class="navbar-toggle collapsed" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="javascript:void(0)" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard">
        <!--img src="assets/images/logo.png" alt="" /-->
        <span class="logo-name">{{'header.aiwizard' | translate}}</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item">
          <app-language-selector></app-language-selector>
        </li>
        <li class="nav-item user_profile" ngbDropdown>
          <a href="javascript:void(0)" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <i class="fas fa-user"></i>
          </a>
          <div ngbDropdownMenu class="notification-dropdown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="javascript:void(0)" [routerLink]="['/profile']">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>{{'header.account' | translate}}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()" [routerLink]="['/authentication/signin']">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>{{'header.logout' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
